<template>
    <a-modal v-model:visible="visible" :title="title" width="888px" :footer="false" destroyOnClose>
        <a-tabs tabPosition="right" v-model:activeKey="active_tab">
            <a-tab-pane key="0" tab="概要" force-render>
                <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
                    <a-form-item label="入库流水号" style="margin:0">{{ serial }}</a-form-item>
                    <a-form-item label="当前节点" style="margin:0">{{ flow_node }}</a-form-item>
                    <a-form-item label="物料数量" style="margin:0">共 {{ material_list.length }} 项 <a-button @click="active_tab = '1'" type="link">查看详情</a-button></a-form-item>
                    <a-form-item label="数据创建人" style="margin:0"><a-avatar :src="create_user_avatar"></a-avatar> {{ create_user_name }}</a-form-item>
                    <a-form-item label="创建时间" style="margin:0">{{ create_time }}</a-form-item>
                    <a-form-item label="最后经办人" style="margin:0"><a-avatar :src="update_user_avatar"></a-avatar> {{ update_user_name }}</a-form-item>
                    <a-form-item label="更新时间" style="margin:0">{{ update_time }}</a-form-item>
                    <a-form-item label="操作记录" style="margin:0">共 {{ flow_logs.length }} 条 <a-button @click="active_tab = '2'" type="link">查看详情</a-button></a-form-item>
                </a-form>
            </a-tab-pane>
            <a-tab-pane key="1" tab="物料清单">
                <a-table :columns="columns" :data-source="material_list" bordered size="small" rowKey="index" :pagination="false"> </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="历史记录" force-render>
                <a-timeline style="margin:8px 2vw">
                    <a-timeline-item v-for="item in flow_logs" :key="item.timestamp" :color="item.color">
                        <div style="display:flex;align-items:center">
                            <strong style="">{{ item.node }}</strong>
                            <span style="margin-left:6px;flex-grow:1">{{ item.time }}</span>
                            <a-avatar :src="item.user_avatar" :size="24"></a-avatar>
                            <strong style="margin-left:6px">{{ item.user_name }}</strong>
                        </div>
                        <div v-if="item.remark">操作备注：{{ item.remark }}</div>
                        <div v-else style="color:#aaa">暂无备注</div>
                    </a-timeline-item>
                </a-timeline>
            </a-tab-pane>
        </a-tabs>
        <a-divider />
        <a-form-item label="操作备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 16 }" v-if="flow_node == '等待入库' && $store.getters.checkPermission('material.inventory.in_storage.audit')">
            <a-input v-model:value="remark" />
        </a-form-item>
        <a-form-item label="操作备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 16 }" v-if="flow_node == '已入库' && $store.getters.checkPermission('material.inventory.in_storage.undo')">
            <a-input v-model:value="remark" />
        </a-form-item>
        <a-form-item label="操作备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 16 }" v-if="flow_node == '等待撤销' && $store.getters.checkPermission('material.inventory.in_storage.undo_audit')">
            <a-input v-model:value="remark" />
        </a-form-item>
        <div style="display:flex;justify-content:center">
            <a-button style="margin:0 10px" @click="operate('同意入库')" type="primary" v-if="flow_node == '等待入库' && $store.getters.checkPermission('material.inventory.in_storage.audit')">同意入库</a-button>
            <a-button style="margin:0 10px" @click="operate('拒绝入库')" v-if="flow_node == '等待入库' && $store.getters.checkPermission('material.inventory.in_storage.audit')">拒绝入库</a-button>
            <a-button style="margin:0 10px" @click="operate('撤销入库')" type="danger" v-if="flow_node == '已入库' && $store.getters.checkPermission('material.inventory.in_storage.undo')">撤销入库</a-button>
            <a-button style="margin:0 10px" @click="operate('同意撤销')" type="danger" v-if="flow_node == '等待撤销' && $store.getters.checkPermission('material.inventory.in_storage.undo_audit')">同意撤销</a-button>
            <a-button style="margin:0 10px" @click="operate('拒绝撤销')" v-if="flow_node == '等待撤销' && $store.getters.checkPermission('material.inventory.in_storage.undo_audit')">拒绝撤销</a-button>
        </div>
    </a-modal>
</template>

<script>
/**
 * 使用方法，为该组件添加ref属性，然后调用该组件的show方法
 */
export default {
    name: 'InStorage',
    data() {
        return {
            visible: false,
            title: '',
            serial: '',
            flow_logs: [],
            flow_node: '',
            material_list: [],
            remark: '',
            columns: [
                { title: '物料编码', dataIndex: 'material_code' },
                { title: '名称', dataIndex: 'name' },
                { title: '批号', dataIndex: 'batch_number' },
                { title: '供应商', dataIndex: 'supplier_name' },
                { title: '储存区', dataIndex: 'storage_name' },
                { title: '入库数量', dataIndex: 'count' },
                { title: '单位', dataIndex: 'unit' },
            ],
            supplier_list: [],
            storage_list: [],
            active_tab: '0',
            create_user_name: '',
            create_user_avatar: '',
            create_time: '',
            update_user_name: '',
            update_user_avatar: '',
            update_time: '',
        };
    },
    watch: {
        visible(value) {},
    },
    mounted() {
        this.$api('getStorageList', { parent_id: -1 }).then((res) => {
            this.storage_list = res.storage_list;
        });
        this.$api('getTotalSupplierList').then((res) => {
            this.supplier_list = res.supplier_list;
        });
    },
    methods: {
        show: function(serial) {
            this.serial = serial;
            this.visible = true;
            this.getData();
        },
        getData() {
            this.$api('getInStorageDetail', { serial: this.serial }).then((res) => {
                this.flow_node = res.in_storage_detail.flow_node;
                this.title = `入库单 - 【${this.flow_node}】`;
                this.material_list = [];
                res.in_storage_detail.material_list.forEach((material) => {
                    this.storage_list.forEach((storage) => {
                        if (material.storage_id == storage.id) material.storage_name = storage.name;
                    });
                    this.supplier_list.forEach((supplier) => {
                        if (material.supplier_id == supplier.supplier_id) material.supplier_name = supplier.name;
                    });
                    material.index = res.in_storage_detail.material_list.indexOf(material);
                    this.material_list.push(material);
                });
                this.flow_logs = [];
                res.in_storage_detail.flow_logs.forEach((log) => {
                    this.$api('getUserInfo', { user_id: log.user_id }).then((res) => {
                        log.user_name = res.name;
                        log.user_avatar = res.avatar;
                        log.color = { 发起入库: 'blue', 同意入库: 'green', 拒绝入库: 'red', 撤销入库: 'orange', 拒绝撤销: 'red', 同意撤销: 'gray' }[log.node];
                        this.flow_logs.push(log);
                    });
                });
                let create_user_id = res.in_storage_detail.flow_logs[0].user_id;
                this.$api('getUserInfo', { user_id: create_user_id }).then((res) => {
                    this.create_user_name = res.name;
                    this.create_user_avatar = res.avatar;
                });
                this.create_time = res.in_storage_detail.flow_logs[0].time;
                let update_user_id = res.in_storage_detail.flow_logs[res.in_storage_detail.flow_logs.length - 1].user_id;
                this.$api('getUserInfo', { user_id: update_user_id }).then((res) => {
                    this.update_user_name = res.name;
                    this.update_user_avatar = res.avatar;
                });
                this.update_time = res.in_storage_detail.flow_logs[res.in_storage_detail.flow_logs.length - 1].time;
            });
        },
        operate(operation) {
            let t = () => {
                this.$api('operateInStorage', { operation, serial: this.serial, remark: this.remark }).then((res) => {
                    if (res.result) {
                        this.$message.success('操作完成');
                        this.remark = '';
                        this.getData();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            };
            switch (operation) {
                case '同意撤销':
                case '同意入库':
                    this.$modal.confirm({
                        title: '确认同意？',
                        content: '确认后将即刻更新物料库存，请核实后再确认！',
                        onOk: t,
                    });
                    break;
                case '拒绝入库':
                    this.$modal.confirm({
                        title: '确认拒绝？',
                        content: '拒绝后将驳回此条入库记录，同时记录失效！',
                        onOk: t,
                    });
                    break;
                case '撤销入库':
                    this.$modal.confirm({
                        title: '确认撤销？',
                        content: '确认后将发起撤销入库申请，未同意前库存不会更改。',
                        onOk: t,
                    });
                    break;
                case '拒绝撤销':
                    this.$modal.confirm({
                        title: '确认拒绝？',
                        content: '拒绝后将驳回此次撤销申请，不会更改库存。',
                        onOk: t,
                    });
                    break;
            }
        },
    },
};
</script>

<style></style>
